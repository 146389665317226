import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
// import Roster from './Icons/user-alt-duotone.svg'
// import Tickets from './Icons/ticket-duotone.svg'
// import Tshirt from './Icons/tshirt-duotone.svg'
// import Calendar from './Icons/calendar-alt-duotone.svg'

import { 
    Box,
    Flex,
    Button,
    Link,
} from '@chakra-ui/react'

import BuyTicketsModal from '../../MooseHockeyModals/BuyTicketsModal'
import WatchLiveModal from '../../MooseHockeyModals/WatchLiveModal'
import MerchandiseModal from '../../MooseHockeyModals/MerchandiseModal'

const MooseHockey = (props) => {

    return (
        <Box>
        
            <Box mb="4" display={props.imageDisplay}>
                <StaticImage src="../../../images/hockey-locker-room.jpg" fullWidth placeholder="blurred" />
            </Box>

            <Box my="8">
                <Flex w="100%" flexWrap="wrap">
                    {/* <Box w={{sm: '100%', md: '50%', lg: '25%'}} textAlign="center" mb="4">
                        <Flex flexDirection="column" p="2" h="100%">
                            <Box mb="4"><img src={Roster} alt="team roster icon" width="50%" style={{ marginLeft: 'auto', marginRight: 'auto'}}/></Box>
                            <Box mt="auto"><Button variant="black-button" isFullWidth size="lg" textTransform="uppercase">Team Roster</Button></Box>
                        </Flex>
                    </Box> */}
                    <Box w={{sm: '100%', md: '50%', lg: '33.33%'}} textAlign="center" mb="4">
                       <BuyTicketsModal button />
                    </Box>
                    <Box w={{sm: '100%', md: '50%', lg: '33.33%'}}  textAlign="center" mb="4">
                       <WatchLiveModal button />
                    </Box>
                    <Box w={{sm: '100%', md: '50%', lg: '33.33%'}}  textAlign="center" mb="4">
                       <MerchandiseModal button />
                    </Box>
                </Flex>
            </Box>

        </Box>
    )
}

export default MooseHockey