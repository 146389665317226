import React from "react";
import { 
  Box,
  Flex
 } from '@chakra-ui/react'
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import Ice from "../../images/Ice.jpg"
import InfoOval from "./InfoOval";

const IceMask = styled.svg`
  width: 100vw;
  height: 100%;
  .cls-1 {
        fill: none;
      }

      .cls-2 {
        clip-path: url(#clip-path);
      }

      .cls-3 {
        isolation: isolate;
      }

      .cls-4 {
        fill: #cc2027;
      }

      .cls-5 {
        clip-path: url(#clip-path-2);
      }
  `

const IceBackground = () => (
  <StaticImage src="../../images/Ice.jpg" layout="fullWidth" placeholder="blurred" />
  )
 

const HomeIceMask = (props) => {
  return (
<Flex w="100%" h="100%" display={{base: 'none', lg: 'block'}} >
  <Box w="100%" h="100%" position="absolute">
    <Flex width="100%" h="100%" justifyContent="flex-start" alignItems="flex-start">
      <InfoOval 
      {...props} />    
  </Flex>
  </Box>
<IceMask xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 3840 2755.80209">
  <defs>
    <clipPath id="clip-path" transform="translate(0)">
      <path class="cls-1" d="M2155.90649,1054.07862C2255.01,1022.88917,3561.39478,591.692,3840,499.7154V0H0V2196.992c88.00732-69.53369,228.9776-164.17163,365.993-185.15576,222.31326-34.04761,424.10611-15.40442,759.35867,87.55225,335.25257,102.95654,2404.5785,608.53759,2404.5785,608.53759l136.35913,47.876H3840V2529.59968c-267.36011-90.157-1497.73584-511.99732-2111.926-843.34912C1036.60034,1313.2049,2044.9198,1089.00776,2155.90649,1054.07862Z"/>
    </clipPath>
    <clipPath id="clip-path-2" transform="translate(0)">
      <path class="cls-1" d="M1728.07385,1686.25056C2342.26392,2017.60236,3572.63989,2439.4427,3840,2529.59968V499.7154c-278.60522,91.97656-1584.99,523.17377-1684.09375,554.36322C2044.9198,1089.00776,1036.60034,1313.2049,1728.07385,1686.25056Z"/>
    </clipPath>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M3529.93005,2707.92624s-2069.3258-505.58088-2404.57837-608.53748c-335.25244-102.9566-537.04541-121.59991-759.35864-87.55225C228.9776,2032.82064,88.00732,2127.45858,0,2196.99228v558.80981H3666.28943Z" transform="translate(0)"/>
      <g id="Layer_2-2" data-name="Layer 2">
        <g id="Layer_4" data-name="Layer 4">
          <g class="cls-2">
            <image class="cls-3" width="3840" height="2501" transform="translate(0) scale(1 1.10188)" xlinkHref={Ice}/>
          </g>
        </g>
        <g id="Layer_1-2-2" data-name="Layer 1-2">
          <path class="cls-1" d="M1166.23682,1967.70673c326.62146,108.58863,2342.67675,641.891,2342.67675,641.891L3840,2735.45441V2580.52985s-1520.82275-503.5686-2234.98755-888.71655,327.24341-616.61853,441.87207-652.68091C2161.51318,1003.07008,3840,449.04278,3840,449.04278V0H0V2134.61848c56.43652-55.57166,244.9845-229.17115,426.42682-259.25037C643.03168,1839.45979,839.6153,1859.118,1166.23682,1967.70673Z" transform="translate(0)"/>
          <path class="cls-4" d="M401.64893,1956.83588c220.105-34.04773,419.89349-15.40441,751.81591,87.55225C1485.38721,2147.34467,3534.158,2652.92561,3534.158,2652.92561l290.1001,102.87622H3840v-20.34742l-331.08643-125.85668s-2016.05529-533.30225-2342.67675-641.891c-326.62152-108.58874-523.20514-128.24694-739.81-92.33862C244.9845,1905.44733,56.43652,2079.04682,0,2134.61848v39.89575C79.30176,2106.42353,243.14764,1981.35419,401.64893,1956.83588Z" transform="translate(0)"/>
          <path d="M1125.35181,2099.3885c335.25244,102.95654,2404.57837,608.53759,2404.57837,608.53759l136.35913,47.876h157.96875L3534.158,2652.92561S1485.38721,2147.34467,1153.46472,2044.388c-331.92236-102.95667-531.71087-121.59986-751.81586-87.55225C243.14764,1981.35419,79.30176,2106.42353,0,2174.51423V2196.992c88.00732-69.53369,228.9776-164.17163,365.993-185.15576C588.30627,1977.78852,790.09918,1996.43183,1125.35181,2099.3885Z" transform="translate(0)"/>
          <path class="cls-1" d="M1166.23682,1967.70673c326.62146,108.58863,2342.67675,641.891,2342.67675,641.891L3840,2735.45441V2580.52985s-1520.82275-503.5686-2234.98755-888.71655,327.24341-616.61853,441.87207-652.68091C2161.51318,1003.07008,3840,449.04278,3840,449.04278V0H0V2134.61848c56.43652-55.57166,244.9845-229.17115,426.42682-259.25037C643.03168,1839.45979,839.6153,1859.118,1166.23682,1967.70673Z" transform="translate(0)"/>
          <path class="cls-4" d="M401.64893,1956.83588c220.105-34.04773,419.89349-15.40441,751.81591,87.55225C1485.38721,2147.34467,3534.158,2652.92561,3534.158,2652.92561l290.1001,102.87622H3840v-20.34742l-331.08643-125.85668s-2016.05529-533.30225-2342.67675-641.891c-326.62152-108.58874-523.20514-128.24694-739.81-92.33862C244.9845,1905.44733,56.43652,2079.04682,0,2134.61848v39.89575C79.30176,2106.42353,243.14764,1981.35419,401.64893,1956.83588Z" transform="translate(0)"/>
          <path d="M1125.35181,2099.3885c335.25244,102.95654,2404.57837,608.53759,2404.57837,608.53759l136.35913,47.876h157.96875L3534.158,2652.92561S1485.38721,2147.34467,1153.46472,2044.388c-331.92236-102.95667-531.71087-121.59986-751.81586-87.55225C243.14764,1981.35419,79.30176,2106.42353,0,2174.51423V2196.992c88.00732-69.53369,228.9776-164.17163,365.993-185.15576C588.30627,1977.78852,790.09918,1996.43183,1125.35181,2099.3885Z" transform="translate(0)"/>
          <path d="M1605.01233,1691.81318C2319.177,2076.96125,3840,2580.52985,3840,2580.52985v-50.93017c-267.36011-90.157-1497.73584-511.99732-2111.926-843.34912-691.47363-373.04566,316.846-597.2428,427.83252-632.172C2255.01,1022.88917,3561.39478,591.692,3840,499.7154V449.04278s-1678.48682,554.0273-1793.11548,590.08961C1932.25586,1075.19477,890.84766,1306.66523,1605.01233,1691.81318Z" transform="translate(0)"/>
          <path class="cls-1" d="M1728.07385,1686.25056C2342.26392,2017.60236,3572.63989,2439.4427,3840,2529.59968V499.7154c-278.60522,91.97656-1584.99,523.17377-1684.09375,554.36322C2044.9198,1089.00776,1036.60034,1313.2049,1728.07385,1686.25056Z" transform="translate(0)"/>
        </g>
      </g>
      <g class="cls-5">
        <image width="1614" height="1171" transform="translate(1010.26558 400.72347) scale(2)" xlinkHref={props.homeImage} />
      </g>
    </g>
  </g>
</IceMask>
</Flex>
  );
};

export default HomeIceMask;
