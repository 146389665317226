
import React from "react";
import styled from "@emotion/styled";
import {
  Box, 
  Heading,
  Container,
} from '@chakra-ui/react'
import MooseHockeyLogo from "../MooseHockeyLogo/MooseHockeyLogo";

const IceMask = styled.svg`
  width: 100vw;
  height: 100%;
  .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #cc2027;
      }
  `

const LowerCurve = (props) => {
  return (
    <Box style={{ position: 'relative'}}>
   <Box display={{base: 'none', lg: 'block'}} mb="-20px">
      <IceMask xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1348 223.00002">
    <defs>
      <style>
  
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-1" d="M402.41248,49.62716C285.46741,25.40474,215.0766,21.01858,137.52777,29.02889,84.20465,34.53683,29.18524,61.605,0,77.985V223H1348v-5.61511l-106.80591-24.58838S519.3576,73.84951,402.41248,49.62716Z"/>
          <path class="cls-2" d="M1241.19409,186.79653S519.3576,67.84951,402.41248,43.62716C285.46741,19.40474,215.0766,15.01858,137.52777,23.02889,84.20465,28.53683,29.18524,55.605,0,71.985v6c29.18524-16.38,84.20465-43.44818,137.52777-48.95612,77.54883-8.01031,147.93964-3.62415,264.88471,20.59827,116.94512,24.22235,838.78161,143.16937,838.78161,143.16937L1348,217.38491v-5.61865Z"/>
          <path d="M1232.2998,176.60287S521.98981,51.13393,406.91241,25.58645C291.835.039,222.5733-4.586,146.25769,3.86214,86.08759,10.52291,23.70551,47.09414,0,62.27193V71.985c29.18524-16.38,84.20465-43.44818,137.52777-48.95612,77.54883-8.01031,147.93964-3.62415,264.88471,20.59827,116.94512,24.22235,838.78161,143.16937,838.78161,143.16937L1348,211.76626V205.9717Z"/>
        </g>
      </g>
    </g>
  </IceMask>
   </Box>
<Box
//   style={{
//   position: 'absolute',
//   top: '5rem',
// }}
w={{md: '100%'}}
  display={{base: 'none', lg: 'block'}}
  bg="white"
>
  {props.logo ? <Box pl="4">
    <MooseHockeyLogo width="28%" /></Box> : (
  <Container maxW="container.xl">
   <Box w="70%">
    <Heading as="h1" variant="page-title" textAlign="left" mt="4">
     {props.pageTitle}
    </Heading>
    </Box>
    <Box>
      <Heading as="h3" fontSize="2.5rem" textAlign="left" fontFamily="lato, sans-serif">
        {props.subTitle}
      </Heading>
   </Box></Container>)}
  
</Box>
<Box
  style={{
  position: 'absolute',
  top: '2.25rem',
  left: '1rem'
  
}}
w={{md: '100%'}}
display={{base: 'block', lg: 'none'}}
>
  {props.logo ? <MooseHockeyLogo width="30%"/> : (
  <><Heading as="h1" variant="page-title" textAlign="left" mt="4">
     {props.pageTitle}
    </Heading>
    <Heading as="h3" fontSize="2.5rem" textAlign="left" fontFamily="lato, sans-serif">
      {props.subTitle}
    </Heading></>
    )}
  
</Box>

{props.secondaryTitle && (
<Box bgColor="white" mt="-1px">
  <Box py="20" px="8" textAlign="center">
  <Heading as="h1" variant="page-title" lineHeight="1">
     {props.pageTitle}
    </Heading>
    <Heading as="h3" color="brand.600" fontSize="2.5rem">
      {props.subTitle}
    </Heading>
</Box>
</Box>)}
    </Box>

  );
};

export default LowerCurve;
