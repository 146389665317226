import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Heading,
  Box,
} from '@chakra-ui/react'

const SecondaryHero = (props) => {
  return (
    <>
      <Box
        display={{base: "none", lg: "block"}}
        style={{
          position: "absolute",
          width: "100%",
          zIndex: "-10",
          marginTop: "-20%",
        }}
      >
        <GatsbyImage image={getImage(props.secondaryImg)} alt={props.alt} />
      </Box>
            
    </>
  );
};

export default SecondaryHero;
