import { Heading, Box, Text, Button, Flex } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";

const InfoOval = (props) => (
  
  (
    <Box id="oval" width="33%" p="14" mr={props.mr} mt={props.mt} ml={props.ml}>
      <Heading as="h2" textAlign="center" mb="2">
        {props.title}
      </Heading>
      <Text textAlign="center">
        <RichText render={props.description} linkResolver={linkResolver} />
      </Text>
      {props.buttonOneText && (
        <Box>
          <Flex justifyContent="center" flexWrap="wrap">
            {props.buttonOneText && <Button to={props.buttonOneLinkTo} as={Link} variant="red-button" m="2">
              {props.buttonOneText}
            </Button>}
            {props.buttonTwoText && <Button to={props.buttonTwoLinkTo} as={Link} variant="black-button" m="2">
              {props.buttonTwoText}
            </Button>}
          </Flex>
        </Box>
      )}
    </Box>
  )
);

export default InfoOval;
