import * as React from "react";
import { graphql } from "gatsby";
import { Box, Container, Heading, Center } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import HomeIceMask from "../components/IceMasks/HomeIceMask";
import SecondaryHero from "../components/SecondaryHero/SecondaryHero";
import HomeLowerCurve from "../components/HomePage/HomeLowerCurve";
import MooseHockey from "../components/HomePage/HomeContent/MooseHockey"
import MooseHockeyLogo from "../components/MooseHockeyLogo/MooseHockeyLogo";

import StripeDivder from "../components/IceMasks/StripeDivider";
import BackgroundImage from '../components/Backgrounds/BackgroundImage'
import ProgramCards from "../components/ProgramsCards/ProgramsCards";
import DonateNewsletterBlock from "../components/DonateNewsletterBlock/DonateNewsletterBlock";
import Footer from "../components/Footer/Footer"
import EventsCarousel from "../components/Events/EventsCarousel";
import SliceZone from "../components/SliceZone/SliceZone";

const IndexPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const HeroImage = document.hero_image?.localFile.publicURL;
  const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  return (
    <>
    <Seo title="Snow King Sports &amp; Events Center" />
      <HomeIceMask
        homeImage={HeroImage} 
        title="The Action is Here"
        description="Don't miss all the excitement happening at the Snow King Ice Rink and Events Center. Our 501(c)(3) is driving Youth and Adult Ice Sports Year Round."
        buttonOneLinkTo="/programs"
        buttonOneText="Our Programs"
        buttonTwoLinkTo="/moose-hockey"
        buttonTwoText="Moose Hockey"
        ml="3%"
        mt="14%"
      />
      <SecondaryHero
        secondaryImg={secondaryImage}
        alt={document.secondary_hero_image.alt}
      />
      <Box
        style={{
          width: "100%",
          marginTop: "15%",
          marginBottom: '-1px'
        }}
        display={{base: 'none', lg: 'block'}}
      >
        <HomeLowerCurve
          logo
          pageTitle="Jackson Hole Moose Hockey"
          subTitle={<>-26<sup>th</sup> SEASON-</>}
          secondaryTitle
        />
      </Box>
      <Box py="20" display={{ base: 'block', lg: 'none' }}>
        <Center>
          <MooseHockeyLogo width="30%" />
        </Center>
      <Heading as="h1" variant="page-title" textAlign="center" mt="4" fontSize="2.5rem" lineHeight="1"> 
        Jackson Hole Moose Hockey
      </Heading>
          <Heading as="h3" fontSize="2rem" fontWeight="600" color="brand.600" mb="4" textAlign="center">
            -26<sup>th</sup> SEASON-
          </Heading>
        </Box>

      <Box backgroundColor="white">
        <Container maxW="container.xl">
          <MooseHockey />
        </Container>
        
        <StripeDivder />

        <Heading as="h1" variant="page-title" mb="4" mt="20">Sign Up For Programs</Heading>
        <ProgramCards />

        <StripeDivder />

        
        
        <Heading as="h1" variant="page-title" mb="4" mt="20">Upcoming Events</Heading>
        <EventsCarousel />

        <Box mb="16"><SliceZone sliceZone={document.body} /></Box>
        
        <BackgroundImage
          backgroundImage={getImage(backgroundImage)}>
        <StripeDivder />
        
      
        <DonateNewsletterBlock
          title={document.footer_call_to_action_title.text}
          content={document.footer_call_to_action_text.richText}
          btnText={document.footer_call_to_action_button_label}
          btnLink={document.footer_call_to_action_link.url}
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80, breakpoints:[750, 1080, 1366, 1920, 3840])
          }
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "home" } }) {
      edges {
        node {
          uid
          data {
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED )
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
            body {
              ... on PrismicPageDataBodyPageContent {
                id
                slice_type
                primary {
                  page_content {
                    richText
                  }
                }
              }
              ... on PrismicPageDataBodyBannerAdsCarousel {
                id
                slice_type
                primary {
                  banner_ads {
                    document {
                      ... on PrismicBannerAds {
                        id
                        data {
                          banner_ad {
                            mobile_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            ad_link {
                              url
                            }
                            desktop_image {
                              alt
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            footer_call_to_action_title {
              text
            }
            footer_call_to_action_text {
              richText
            }
            footer_call_to_action_link {
              url
            }
            footer_call_to_action_button_label
          }
        }
      }
    }
  }
`;

export default IndexPage;
